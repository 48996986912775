import React from 'react';
import styled from "styled-components";
import { LinkRef, Paragraph } from "../styles";

import profileImage from '../styles/img/profile_p.jpeg';

const AboutContainer = styled.section`
  align: center;
  padding-bottom: 50px;
`;

const LinkContainer = styled.div`
  text-align: center;
  margin-top: 20px;
`;

const Profile = styled.img`
  width: 18vw;
  height: auto;
  display: inline;
  min-width: 200px;
  float: left;
  margin: 0 40px 1.4vw 0;
  border-radius: 4px;
`;

function About() {
  return (
    <AboutContainer>
      <Profile src={profileImage} alt="Jarett Dewbury" className="profile" />
        I'm Jarett Dewbury, a third-year undergraduate studying <LinkRef href="https://uwaterloo.ca/future-students/programs/biomedical-engineering" target="_blank">Biomedical Engineering</LinkRef> at the University of Waterloo.
      <Paragraph>
        I'm interested in the intersection of machine learning and healthcare, particularly in enhancing diagnostic throughput and clinical decision-making. My current research focuses on developing transformative AI-enabled tools 
        tailored for use in low-resource settings. This involves creating robust models that can operate efficiently with minimal data and computational resources.
        I aim to contribute to the development of accessible and accurate diagnostic solutions that can have a significant impact on global healthcare.
      </Paragraph>
      <Paragraph>
        Currently, I'm a visiting researcher at <LinkRef href="https://www.mit.edu/" target="_blank">Massachusetts Institute of Technology</LinkRef> with Loza Tadesse working on machine learning for low-resource diagnostics. I'm the co-founder 
        of <LinkRef href="https://watai.ca/" target="_blank">WAT.ai</LinkRef>, the University of Waterloo's artificial intelligence student design team. I'm also an undergraduate researcher in the <LinkRef href="https://vip.uwaterloo.ca/" target="_blank">Vision and Image Processing (VIP) Lab</LinkRef> at  
        the University of Waterloo working with Amy Tai on computer vision for prostate cancer segmentation. Previously, I was a machine learning researcher at <LinkRef href="https://hms.harvard.edu/" target="_blank">Harvard Medical School</LinkRef> and <LinkRef href="https://www.massgeneral.org/" target="_blank">Massachusetts General Hospital</LinkRef> working
        with OCT images for on-device segmentation of the gastrointestinal tract.
      </Paragraph>
      <Paragraph>
        I'm grateful to be supported by the <LinkRef href="https://www.mercatus.org/emergent-ventures" target="_blank">Emergent Ventures Fellowship</LinkRef>.
      </Paragraph>
      <Paragraph>
        I enjoy staying active and engaging in a variety of sports and outdoor activities. Before attending university, I spent a large amount of my early life playing 
        junior <LinkRef href="https://www.eliteprospects.com/player/582668/jarrett-dewbury" target="_blank">hockey</LinkRef> and pursuing my athletic goals.
      </Paragraph>
      <Paragraph>
            If you share similar interests or are seeking guidance in your own academic or professional endeavors, feel free to reach out to me!
      </Paragraph>
      <LinkContainer>
        <LinkRef href="mailto:jdewbury@uwaterloo.ca" target="_blank">Email</LinkRef>&#160;〳&#160;
        <LinkRef href="https://twitter.com/JarettDewbury" target="_blank">Twitter</LinkRef>&#160;〳&#160;
        <LinkRef href="https://github.com/Jdewbury" target="_blank">Github</LinkRef>&#160;〳&#160;
        <LinkRef href="https://www.linkedin.com/in/jarett-dewbury/" target="_blank">LinkedIn</LinkRef>&#160;〳&#160;
        <LinkRef href="https://scholar.google.com/citations?user=coiG25kAAAAJ&hl=en&oi=ao" target="_blank">Google Scholar</LinkRef>
      </LinkContainer>
    </AboutContainer>
  );
}

export default About;
