import React from 'react';
import About from './About';
import Research from './Research';
import News from './News';

function HomePage() {
  return (
    <div>
      <About />
      <Research />
      <News />
    </div>
  );
}

export default HomePage;