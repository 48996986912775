import React from 'react';
import { Helmet } from 'react-helmet';
import styled from 'styled-components';
import { Paragraph } from '../styles';
import '../styles/index.css';

const ProjectContainer = styled.section`
  margin-top: -50px;
  font-size: 18px;
`;

const ProjectTitle = styled.h1`
  text-align: left;
  font-size: 50px;
  margin-bottom: -10px;
  font-family: 'AnonymousPro', Arial, sans-serif;
  font-weight: bold;
`;

const Authors = styled.div`
  text-align: left;
  font-size: 20px;
`;

const AuthorName = styled.a`
  margin: 0px;
  color: #6eb1f8;
  border-bottom: 0.1px #a5a5a5 dotted;
  transition: color 0.3 ease-in;
  text-decoration: none;
  &:hover {
    color: #333;
  }
`;

const Superscript = styled.span`
  font-size: 0.8em;
  vertical-align: super;
`;

function SpectroMIND() {
  return (
    <ProjectContainer>
      <Helmet>
        <title>SpectroMIND</title>
      </Helmet>
      <ProjectTitle>SpectroMIND</ProjectTitle>
      <Paragraph>
      <Authors>
        <AuthorName href="https://github.com/yourusername/yourproject" target="_blank">Jarett Dewbury</AuthorName><Superscript>1</Superscript>, <AuthorName href="https://github.com/yourusername/yourproject" target="_blank">Loza Tadesse</AuthorName><Superscript>1</Superscript>
      </Authors>
      </Paragraph>
      <Paragraph>
      SpectroMIND is a powerful framework designed to streamline the process of uploading, preprocessing, and training machine learning models on spectral data. 
      It is tailored for seamless integration into existing workflows and offers advanced analytics capabilities to enhance your research and applications in spectroscopy.
      </Paragraph>
    </ProjectContainer>
  );
}

export default SpectroMIND;