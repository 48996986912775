import React from 'react';
import { Title } from "../styles";
import styled from 'styled-components';

const NewsContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 5px;
`;

const NewsDate = styled.span`
  color: #000005;
  padding-top: 1px;
  width: 70px;
  font-weight: bold;
  font-size: 12px;
`;

const NewsDescription = styled.span`
  flex: 1;
`;

function News() {
  const newsItems = [
    { date: 'May 2024', description: 'Back for my second term at Massachusetts Institute of Technology as a visiting researcher.' },
    { date: 'Jan 2024', description: 'Joined the Vision and Image Processing (VIP) Lab at the University of Waterloo as an undergraduate researcher.' },
    { date: 'Aug 2023', description: 'Joined Massachusetts Institute of Technology as a visiting researcher.' },
    { date: 'Apr 2023', description: 'Awarded the Emergent Ventures Fellowship, supported by the Thiel Foundation.' },
    { date: 'Jan 2023', description: 'Joined Harvard Medical School and Massachusetts General Hospital as an undergraduate researcher.' },
    { date: 'Jun 2022', description: 'Co-founded WAT.ai, the University of Waterloo\'s Artificial Intelligence Student Design Team.' },
    { date: 'May 2022', description: 'Joined the Medical AI Lab at the University of Waterloo as a member of the founding research team.' }
];

  return (
    <section id="news">
      <Title>News</Title>
      {newsItems.map((item, index) => (
        <NewsContainer key={index}>
          <NewsDate>{item.date}</NewsDate>
          <NewsDescription>{item.description}</NewsDescription>
        </NewsContainer>
      ))}
    </section>
  );
}

export default News;